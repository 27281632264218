body {
  margin: 0;
  padding: 0;
  background-image: url("./assets/images/bgLanding.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  cursor: crosshair;
}

.jacquard-12-regular {
  font-family: "Jacquard 12", system-ui;
  font-weight: 400;
  font-style: normal;
}


.bow {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
}

.logo {
  animation: fadeIn 0.5s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10vh;
}

.logo-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  align-self: center;
  flex-direction: column;
}

.tabbed-interface {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-button {
  background-color: #F8C0D9;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 11vh;
  width: 33.7vh;
  border-radius: 2vh;
}

.tab-button.active {
  background-color: #21ff37;
}

.tab-content {
  align-items: center;
}

.code,
.about,
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 30vh;
}

.artwork {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 30vh;
  padding-left: 1vh;
}


.guestbook {
  width: 33vh;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proj-title {
  color: #ff0672;
  font-size: 10vh;
  text-align: center;
  background-image: url("./assets//images/bgLanding.jpeg");
  margin: 10vh;
}

.video {
  height: auto;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  display: flex;
  height: 150vh;
}

.commissions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5vh;
  width: 30vh;
  padding-left: 2vh;
}

.box-container {
  height: auto;
  width: auto;
  background-color: #21ff37;
  background-image: url("assets/images/pinkbg.jpeg");
  background-size: cover;
  background-attachment: fixed;
  margin-top: -2vh;

}

.github {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
}

.github-image {
  height: 30vh;
  background-image: url("./assets/images/flamesGif.gif");
  margin: 5vh;
  padding: 5vh;
}

.title {
  font-size: 10vh;
  color: #ff0672;
  text-align: center;
  background-color: #21ff37;
  padding: 5vh;
  margin: 5vh;
  text-align: center;
}

.caption {
  font-size: 5vh;
  color: #ff0672;
  text-align: center;
}

.scroll {
  overflow: auto;
}

.code-about {
  font-size: 5vh;
  text-align: center;
  color: #ff0672;
}

.about-container {
  display: flex;
  align-items: flex-start;
}

.selfie {
  height: 80vh;
  padding-left: 5vh;
  padding-top: 10vh;
}

.selfie3 {
  position: absolute;
  height: 80vh;
  margin-top: -140vh;
  padding-left: 70vh;
}

.kirby {
  position: relative;
  padding-left: 30vh;
  margin-top: 10vh;
}



.bow {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  height: 300px;
  padding-top: 60px;
  margin-bottom: 30vh;
}

.form {
  height: 400px;
}

.bowchain {
  margin-top: 2vh;
  width: 169vh;
  height: 40vh;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.commission-description {
  color: #ff0672;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./assets//images/bgLanding.jpeg");
  margin: 5vh;
  text-align: center;
}

.cake {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 300px;
  margin: 0 auto;
  margin-top: 10vh;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: #21ff37 solid 1px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.submit-button {
  padding: 10px 20px;
  background-color: #21ff37;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button:hover {
  background-color: #ff9cf7;
}

.modal {
  background-image: url("./assets/images/skulzz.jpeg");
  color: #21ff37;
  text-align: center;
  font-size: 30px;
}

.piano {
  height: 32vh;
  margin: 6vh;
  margin-left: 10vh;
}

.modal-button {
  height: 5vh;
  width: 5vh;
  background-color: #21ff37;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 15vh;
  flex-direction: column;
}

.carousel-img {
  height: 70vh;
}

.carousel-caption {
  text-align: center;
}

.art-about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  padding-top: 15vh;
  flex-direction: column;
  color: #ff0672;
  margin-bottom: 5vh;
}

.twin {
  height: 20vh;
  margin-bottom: 10vh;
}

.ig {
  margin-bottom: 5vh;
}

.art-descrip {
  background-image: url("./assets//images/bgLanding.jpeg");
  padding: 5vh;
  margin: 5vh;
  text-align: center;
}

.notepad {
  position: absolute;
  height: 100vh;
  width: 60vh;
}

.notepad-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  padding-left: 5vh;
  margin-top: 5vh;
}

.notepad-text {
  position: absolute;
  color: #000000;
  font-size: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40vh;
  padding-top: 8vh;
}

.notepad-bg {
  height: 95vh;
  width: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloud {
  position: absolute;
  left: 60vh;
  margin-top: -60vh;
  height: 70vh;
}

.bun {
  padding-top: 15vh;
  padding-left: 5vh;
}

.couple {
  height: 70vh;
  margin-left: 30vh;
}

.butterfly {
  display: block;
  height: 40vh;
  padding-left: 9vh;
}

.objective {
  background-image: url("./assets/images/bgLanding.jpeg");
  margin: 10vh;
}

.skills {
  background-image: url("./assets/images/bgLanding.jpeg");
  margin: 10vh;
}

.gotDescrip {
  background-image: url("./assets/images/bgLanding.jpeg");
  margin: 10vh;
}

.brain {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10vh;
}

.gbHeader {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0672;
  text-align: center;
  
}

.gbTitle {
  margin-top: 5vh;
  background-image: url("./assets/images/bgLanding.jpeg");
  font-size: 8vh;
}
 
.gb {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 5vh;
  border-radius: 10vh;
  font-family: "Jacquard 12", system-ui;
}

.bubbles {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 20vh;
}

.ipod-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ipod-image {
  height: 30vh;
}

.audio-thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio-thumbnail {
  height: 14vh;
  width: 18vh;
  border-radius: 1vh;
  position: absolute;
  margin-top: -41vh;
}

.audio-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -9vh;
}


.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  color: #21ff37;
  padding: 10px 0;
  font-size: 3vh;
  margin-bottom: 5vh;
  font-family: "Jacquard 12", system-ui;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}

.input {
  border-radius: 3vh;
  opacity: 60%;
  width: 50vh;
  height: 10vh;
  font-size: 5vh;
}


@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.entries {
  text-align: center;
  color: #000000;
  font-size: 3vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40vh;
  background-image: url('./assets/images/pinkborder.png');
  font-family: "Jacquard 12", system-ui;
}

.artDescriptions {
  text-align: center;
  font-size: 5vh;
  font-family: "Jacquard 12", system-ui;
}

.heartWings {
  float: left;
  display: block;
  position: absolute;
  left: 1%;
  bottom: 5%;
  transform: scaleX(-1);
}

.heartWings2 {
  float: right;
  display: block;
  position: absolute;
  right: 1%;
  bottom: 5%;
}

@media only screen and (max-width: 1180px) {
  .logo-landing {
    height: 26vh;
  }


  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabbed-interface {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tab-button {
    background-color: #F8C0D9;
    padding: 1vh;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 35vh;
    border-radius: 2vh;
    margin: 1vh;
    margin-bottom: 2vh;
  }

  .tab-button.active {
    background-color: #21ff37;
  }

  .tab-content {
    align-items: center;
  }

  .box-container {
    height: auto;
    width: 40vh;
    background-image: url("assets/images/pinkbg.jpeg");
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
  }

  .code-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10vh;
    padding-right: 5vh;
    margin: 40vh;
  }

  .objective {
    background-image: url("./assets/images/bgLanding.jpeg");
    text-align: center;
    font-size: 5vh;
    display: inline;
    margin-top: -80vh;
    margin-right: 20vh;
  }

  .obj-descrip {
    width: 25vh;
    text-align: center;
    padding-right: 10vh;
    font-size: 3vh;
  }

  .skills {
    background-image: url("./assets/images/bgLanding.jpeg");
    font-size: 6vh;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin-left: 2vh;
  }

  .skills-descrip {
    margin: 10vh;
    padding-right: 10vh;
  }

  .description {
    margin: 30vh;
    color: #ff0672;
    font-size: 5vh;
    margin-left: 20vh;
    text-align: center;
    margin-top: -7vh;
  }

  .bun-description {
    color: #ff0672;
    font-size: 5vh;
    text-align: center;
    margin: 60vh;
    margin-top: -7vh;
    margin-bottom: 10vh;
  }

  .got-description {
    color: #ff0672;
    text-align: center;
    font-size: 5vh;
    margin: 70vh;
    padding-right: 8vh;
  }

  .caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 5vh;
  }

  .github {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .github-image {
    height: 10vh;
    background-image: url("./assets/images/flamesGif.gif");
    background-position: center;
    position: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .code,
  .contact,
  .artwork {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vh;
    padding-left: 7vh;
    height: 5vh;
  }

  .commissions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vh;
    height: 5.5vh;
  }

  .guestbook {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about {
    height: 5vh;
    width: 24vh;
    padding-left: 4vh;
  }

  .kirby {
    height: 30vh;
    justify-content: center;
    align-items: center;
  }

  .meet {
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    height: 30vh;
    margin-top: 10vh;
  }

  .bowchain {
    width: 40vh;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .commission-description {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #21ff37;
    padding: 1vh;
    text-align: center;
  }

  .cake {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-bottom: 4vh;
  }

  .form-container {
    width: 35vh;
    margin: 0 auto;
  }

  .input-field {
    width: 100%;
    margin-bottom: 5vh;
    border: #21ff37 solid 1px;
    border-radius: 5px;
    box-sizing: border-box;
    background: none;
    background-color: #ffffff;
  }

  .submit-button {
    background-color: #21ff37;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input {
    border-radius: 3vh;
    opacity: 60%;
    width: 30vh;
    height: 8vh;
    font-size: 5vh;
  }

  .submit-button:hover {
    background-color: #ff9cf7;
  }

  .proj-title {
    font-size: 3vh;
    background-image: url("./assets//images//bgLanding.jpeg");
    margin: 1vh;
    margin-right: 6vh;
  }

  .gbTitle {
    margin-top: 5vh;
    background-image: url("./assets/images/bgLanding.jpeg");
    font-size: 5vh;
  }

  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 15vh;
  }

  .carousel-img {
    height: 40vh;
  }

  .video {
    height: 60vh;
    padding-right: 2vh;
    margin-bottom: 1vh;
  }

  .gotDescrip {
    background-image: url("./assets/images/bgLanding.jpeg");
    font-size: 2vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3vh;
    padding: 1vh;
    padding-right: 4vh;
  }

  .brain {
    width: 35vh;
    height: 40vh;
    padding-left: 40.5%;
    margin-top: -15vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .twin {
    padding-bottom: 10vh;
  }

  .ballons {
    height: 40vh;
  }

  .entries {
    background-image: url("./assets/images/pinkborder.png");
    text-align: center;
    color: #000000;
    font-size: 3vh;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Jacquard 12", system-ui;
  }

  .notepad {
    height: 143vh;
    width: 35vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notepad-text {
    width: 24vh;
    height: 8vh;
    text-align: center;
    font-size: 14px;
    margin-top: 4vh;
  }

  .butterfly {
    height: 42vh;
    padding-left: 10vh;
  }

  .notepad-container {
    padding-top: 25vh;
    padding-right: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .notepad-bg {
    width: 30vh;
    height: 99vh;
  }

  .couple {
    height: 40vh;
    padding-right: 20vh;
  }

  .selfie3 {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
  }

  .kirby {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 30vh;
    padding-bottom: 10vh;
  }

  .bun {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
  }

  .cloud,
  .selfie,
  .selfie3 {
    visibility: hidden;
  }

  .couple {
    margin-top: -2vh;
    margin-right: 10vh;
  }

  .carousel-img {
    padding: 1vh;
  }

  .heartWings2 {
    position: inherit;
    height: 50vh;
    margin-bottom: -10vh;
    margin-top: -10vh;
  }

  .heartWings {
    margin: 1vh;
    position: relative;
    height: 50vh;
    margin-bottom: -10vh;
    margin-top: -10vh;
  }

}